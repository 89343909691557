import { HierarchyCard } from '@yes.technology/react-toolkit'
import styled from 'styled-components'

export const ActiveSessionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  width: 100%;
  max-width: 448px;
  margin: 0 auto;
  gap: 16px;
`

export const ActiveSessionTitle = styled.h1`
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  margin: 0;
`

export const UserDataHierarchyCard = styled(HierarchyCard)`
  & > *:first-child {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`
