import { startAuthentication } from '@simplewebauthn/browser'

import {
  generateAuthenticationOptions,
  verifyAuthentication
} from 'authentication/shared/api'

export async function authentication(userId: string) {
  const resp = await generateAuthenticationOptions(userId)

  let credentialJSON

  try {
    let { publicKey } = await resp?.data?.data

    publicKey = {
      ...publicKey,
      excludeCredentials: []
    }

    // Pass the options to the authenticator and wait for a response
    credentialJSON = await startAuthentication(publicKey)
  } catch (error) {
    let errorMessage = ''

    if (error instanceof Error) {
      errorMessage = error.message
    }
    console.log('error', errorMessage)
    return {
      success: false,
      message: errorMessage || 'Erro desconhecido'
    }
  }

  const verificationResp = await verifyAuthentication(userId, credentialJSON)

  const verificationJSON = await verificationResp?.data

  return {
    success: verificationJSON && verificationJSON.success,
    data: verificationJSON?.data
  }
}
