import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18n-lite'

import { useDocumentTitle } from 'infrastructure/shared/hooks'

import {
  useAuthentication,
  useCredentials,
  useRedirectOrigin
} from 'authentication/shared/hooks'

import AuthenticationTemplate from 'authentication/shared/components/AuthenticationTemplate'
import {
  Button,
  DataFieldSelection,
  feedbackcard
} from '@yes.technology/react-toolkit'
import {
  CredentialOptions,
  CredentialResponse
} from 'authentication/shared/types'
import SuggestionChangePassword from 'authentication/Login/LoginContainer/SuggestionChangePassword'
import usePasswordChangeSuggestion from 'authentication/shared/hooks/usePasswordChangeSuggestion'

const getInitialSelectedCredentials = (credentials: CredentialResponse[]) => {
  return credentials.reduce(
    (acc, credential) => ({
      ...acc,
      [credential.uuid]: credential.options[0]
    }),
    {}
  )
}

const Credentials = () => {
  const { t } = useTranslation()
  const { credentials, setCredential } = useCredentials()

  const { redirectToOrigin } = useRedirectOrigin()

  const { authenticatedData } = useAuthentication()

  const [selectedCredentials, setSelectedCredentials] = useState<{
    [key: string]: CredentialOptions
  }>({})

  const [shouldDisableSubmit, setShouldDisableSubmit] = useState(false)

  useEffect(() => {
    setSelectedCredentials(getInitialSelectedCredentials(credentials))
  }, [credentials])

  const getOptionSetter = (credentialId: string) => {
    return ([newOption]: CredentialOptions[]) => {
      setSelectedCredentials((oldCredentials) => ({
        ...oldCredentials,
        [credentialId]: newOption
      }))
    }
  }

  const { shouldOpenPasswordChange, handleSuggestPasswordChange } =
    usePasswordChangeSuggestion()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setShouldDisableSubmit(true)

    await setCredential(selectedCredentials, async () => {
      feedbackcard(t('credentials.feedback.success.title'), {
        message: t('credentials.feedback.success.msg'),
        type: 'success'
      })

      const isRedirectToOrigin = !handleSuggestPasswordChange(authenticatedData)

      if (isRedirectToOrigin) {
        redirectToOrigin()
      } else {
        setShouldDisableSubmit(false)
      }
    })
  }

  useDocumentTitle({ title: t('credentials.title') })

  return (
    <AuthenticationTemplate>
      <h2>{t('credentials.title')}</h2>

      {credentials && (
        <form
          style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
          onSubmit={handleSubmit}
        >
          {credentials.map((credential) => (
            <DataFieldSelection
              key={credential.uuid}
              label={credential.des}
              options={credential.options}
              selected={[selectedCredentials[credential.uuid]]}
              onChange={getOptionSetter(credential.uuid)}
              required
            />
          ))}
          <Button
            disabled={shouldDisableSubmit}
            impactClass='workflow'
            type='submit'
          >
            {t('credentials.apply')}
          </Button>
        </form>
      )}
      <SuggestionChangePassword isOpen={shouldOpenPasswordChange} />
    </AuthenticationTemplate>
  )
}

export default Credentials
