import React, { useCallback, useEffect, useState } from 'react'

import { AuthenticationContext } from 'authentication/shared/hooks'
import { useLocalStorage } from 'shared/hooks'
import { useSearchParams } from 'react-router-dom'
import getAuthDataFromToken from 'shared/utils/getAuthDataFromToken'
import { AuthenticatedData } from 'authentication/shared/types'

type AuthenticationProviderProps = {
  children: React.ReactNode
}

const AuthenticationProvider = ({ children }: AuthenticationProviderProps) => {
  const [loadingCount, setLoadingCount] = useState(0)
  const [storedAuthenticatedData, setAuthenticatedData] =
    useLocalStorage('auth')

  const [searchParams, setSearchParams] = useSearchParams()
  const token = searchParams.get('token') || ''

  const authenticatedData: AuthenticatedData =
    storedAuthenticatedData instanceof Object ? storedAuthenticatedData : {}

  if (token) {
    const tokenAuthData = getAuthDataFromToken(token)
    setAuthenticatedData({
      ...authenticatedData,
      ...tokenAuthData
    })
  }

  useEffect(() => {
    if (token) {
      searchParams.delete('token')
      setSearchParams(searchParams, { replace: true })
    }
  }, [searchParams, setSearchParams, token])

  const startLoading = useCallback(
    () => setLoadingCount((prevLoadingCount) => prevLoadingCount + 1),
    [setLoadingCount]
  )

  const stopLoading = useCallback(
    () => setLoadingCount((prevLoadingCount) => prevLoadingCount - 1),
    [setLoadingCount]
  )

  return (
    <AuthenticationContext.Provider
      value={{
        isLoading: loadingCount > 0,
        startLoading,
        stopLoading,
        authenticatedData,
        setAuthenticatedData
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}

export default AuthenticationProvider
