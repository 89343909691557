import {
  Button,
  ExplicitSelectionButton,
  Popup
} from '@yes.technology/react-toolkit'
import {
  useAuthentication,
  useRedirectOrigin
} from 'authentication/shared/hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18n-lite'
import { useNavigate } from 'react-router-dom'
import { patchDontShowChangePassword } from 'authentication/shared/api'

type Props = {
  isOpen: boolean
}

const DONT_ASK_AGAIN_UUID = 'dont-ask-again'

function SuggestionChangePassword({ isOpen }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { redirectToOrigin } = useRedirectOrigin()

  const [dontAskIsChecked, setDontAskIsChecked] = useState(false)
  const { startLoading } = useAuthentication()

  const goToChangePassword = () => {
    navigate(`/change-password${dontAskIsChecked ? '?dontAskAgain=true' : ''}`)
  }

  const continueNotChanging = async () => {
    if (dontAskIsChecked) {
      await patchDontShowChangePassword()
    }

    startLoading()
    redirectToOrigin()
  }

  const onChangeDontAsk = () => {
    setDontAskIsChecked(!dontAskIsChecked)
  }

  return (
    <Popup isOpen={isOpen} onClose={continueNotChanging}>
      <div className='suggestion-change-password'>
        <h1>{t('login.suggestion-change-password.title')}</h1>
        <div className='message'>
          {t('login.suggestion-change-password.to-ensure-data-safety')}
        </div>
        <Button onClick={goToChangePassword} style={{ marginBottom: 8 }}>
          Trocar senha
        </Button>
        <Button variant='secondary' onClick={continueNotChanging}>
          Continuar sem trocar
        </Button>

        <div className='footer'>
          <ExplicitSelectionButton
            heightClass='doubleXSmall'
            direction='vertical'
            optionLabelsPosition='right'
            onChange={onChangeDontAsk}
            options={[
              {
                uuid: DONT_ASK_AGAIN_UUID,
                des: t('login.suggestion-change-password.button-dont-ask-again')
              }
            ]}
            checkedOption={dontAskIsChecked ? DONT_ASK_AGAIN_UUID : ''}
          />
        </div>
      </div>
    </Popup>
  )
}

export default SuggestionChangePassword
