import { feedbackcard } from '@yes.technology/react-toolkit'
import { useCallback, useEffect, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18n-lite'

const useRecaptcha = () => {
  const { t } = useTranslation()
  const [captcha, setCaptcha] = useState<string>('')

  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) return
    executeRecaptcha()
      .then(setCaptcha)
      .catch(() =>
        feedbackcard(t('general-feedback.error.title'), {
          message: t('general-feedback.error.msg'),
          type: 'error'
        })
      )
  }, [executeRecaptcha, t])

  useEffect(() => {
    handleReCaptchaVerify()
  }, [handleReCaptchaVerify])

  return {
    captcha
  }
}

export default useRecaptcha
