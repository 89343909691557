import { useTranslation } from 'react-i18n-lite'
import { useCookies } from 'react-cookie'

import LoginContainer from './LoginContainer'
import { useDocumentTitle } from 'infrastructure/shared/hooks'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Login = () => {
  const { t } = useTranslation()
  const [cookies] = useCookies(['_2FA'])
  const navigate = useNavigate()

  useEffect(() => {
    if (cookies._2FA) {
      navigate('/webauthn/authentication', { replace: true })
    }
  }, [navigate, cookies._2FA])

  useDocumentTitle({ title: t(`login.identification.title`) })
  return <LoginContainer />
}

export default Login
