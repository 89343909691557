import {
  FilterBody,
  Objectclass,
  YesObject
} from '@yes.technology/react-toolkit'
import { type Pagination } from 'shared/types'
import { ProjectFields } from '../hooks/type'
import { HTTPClient } from 'infrastructure/shared/api'

type FetchYesObjectsArgs = {
  filterBody: FilterBody & {
    skip_relations?: boolean
    skip_metadata?: boolean
    project_fields?: ProjectFields
  }
  showLoading?: boolean
}

export type FetchYesObjectsResponse = {
  metadata: Objectclass
  objects: YesObject[] | null
  pagination: Pagination
}

export default async function fetchYesObjects({
  filterBody
}: FetchYesObjectsArgs) {
  const { data } = await HTTPClient.post<{ data: FetchYesObjectsResponse }>(
    `${window.apiUrls.msPlatform}objects/_filter`,
    filterBody
  )

  return {
    metadata: data.data?.metadata,
    objects: data.data?.objects,
    pagination: data.data?.pagination
  }
}
