import { useMemo } from 'react'

export type Value = number | string | object | [] | null

type LocalStorage = [storedValue: unknown, setValue: (value: Value) => void]

const useLocalStorage = (key: string): LocalStorage => {
  const localStoredValue = window.localStorage.getItem(key)

  const storedValue = useMemo(() => {
    return localStoredValue ? JSON.parse(localStoredValue) : null
  }, [localStoredValue])

  const setValue = (value: Value) => {
    window.localStorage.setItem(key, JSON.stringify(value))
  }

  return [storedValue, setValue]
}

export default useLocalStorage
