import { useState } from 'react'
import { useTranslation } from 'react-i18n-lite'
import { Icon, isEmbedded } from '@yes.technology/react-toolkit'
import styled from 'styled-components'

const ComponentStrucuture = styled.div`
  display: flex;
  align-items: center;

  span {
    flex: 1;
  }
`

const IconButton = styled.button`
  background: transparent;
  border: none;

  &:focus {
    box-shadow: unset !important;
  }

  &:hover {
    opacity: 0.8;
  }
`

const EnvironmentWarning: React.FunctionComponent = (): JSX.Element | null => {
  const [isShown, setIsShown] = useState<boolean>(true)
  const { t } = useTranslation()
  const env = window.waEnv
  const isProductionEnv = env === 'production'

  if (isEmbedded() || !isShown || isProductionEnv) return null

  return (
    <ComponentStrucuture className='alert alert-danger rounded-0 mb-0 text-center'>
      <span>{t('system.warning-env', { env })}</span>
      <IconButton onClick={() => setIsShown(false)}>
        <Icon iconName='Close' icSize='xSmall' />
      </IconButton>
    </ComponentStrucuture>
  )
}

export default EnvironmentWarning
