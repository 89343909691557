import jwtDecode from 'jwt-decode'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18n-lite'
import { useSearchParams } from 'react-router-dom'

import { useLocalStorage, useSessionStorage } from 'shared/hooks'
import { Credentials } from '../types/authentication.type'
import { isEmbedded } from '@yes.technology/react-toolkit'

const useRedirectOrigin = () => {
  const [autoRedirect, setAutoRedirect] = useState(false)
  const [authenticatedData] = useLocalStorage('auth')
  const [searchParams, setSearchParams] = useSearchParams()
  const { language } = useTranslation()

  const [redirectOrigin, setRedirectOrigin] = useSessionStorage(
    'redirectOrigin',
    window.fallbackOrigin
  )

  const handleRedirectOrigin = useCallback(() => {
    if (searchParams.get('redirectOrigin')) {
      setRedirectOrigin(searchParams.get('redirectOrigin') || '')
      searchParams.delete('redirectOrigin')
      setSearchParams(searchParams, { replace: true })
    }
  }, [searchParams, setSearchParams, setRedirectOrigin])

  useEffect(handleRedirectOrigin, [handleRedirectOrigin])

  const isCredentialsValid = (credentials: Credentials) =>
    credentials?.applicationsystem_uuid &&
    credentials?.organization_uuid &&
    credentials?.user_uuid

  const token =
    authenticatedData instanceof Object &&
    'token' in authenticatedData &&
    typeof authenticatedData.token === 'string'
      ? authenticatedData.token
      : ''

  const isTokenValid = useCallback(() => {
    try {
      const decodedToken = jwtDecode<{ credentials: Credentials }>(token)
      return isCredentialsValid(decodedToken?.credentials)
    } catch (error) {
      return false
    }
  }, [token])

  const redirectToOrigin = useCallback(() => {
    if (!isTokenValid()) {
      setAutoRedirect(true)
      return
    }

    if (isEmbedded()) {
      window.parent.postMessage({ token }, '*')

      return
    }

    const urlToRedirectWithParams = buildAuthenticatedRedirectUrl(
      redirectOrigin,
      token,
      language
    )

    window.location.assign(urlToRedirectWithParams)
  }, [token, language, redirectOrigin, isTokenValid])

  const handleToken = useCallback(() => {
    if (isTokenValid() && autoRedirect) {
      setAutoRedirect(false)
      redirectToOrigin()
    }
  }, [autoRedirect, isTokenValid, redirectToOrigin])

  useEffect(handleToken, [handleToken])

  return {
    redirectToOrigin
  }
}

function buildAuthenticatedRedirectUrl(
  redirectOrigin: string,
  token: string,
  language: string
): string {
  const redirectOriginURL = new URL(redirectOrigin)
  redirectOriginURL.searchParams.set('token', token)
  redirectOriginURL.searchParams.set('lang', language)
  return redirectOriginURL.toString()
}

export default useRedirectOrigin
