import { useTranslation } from 'react-i18n-lite'
import 'shared/assets/css/login.css'
import { StyledHierarchyCard } from './styles'

type Props = {
  children: React.ReactNode
  title?: string
}

const AuthenticationTemplate = ({ children, title }: Props) => {
  const { t } = useTranslation()
  return (
    <div className='login'>
      <div className='container'>
        <div className='row justify-content-md-center'>
          <StyledHierarchyCard spacingClass='medium' className='loginForm'>
            <h1>{title || t('authentication.title')}</h1>
            {children}
          </StyledHierarchyCard>
        </div>
      </div>
    </div>
  )
}

export default AuthenticationTemplate
