import { locales as toolkitLocales } from '@yes.technology/react-toolkit'
import deepmerge from 'deepmerge'
import { Locales } from 'react-i18n-lite'
import localePtBr from './pt-BR.json'

export const supportedLanguages = ['en-US', 'pt-BR', 'de-DE']
export const defaultLanguage = 'pt-BR'

// Por enquanto estamos liberando para o usuário somente o português
const locales: Locales = {
  'pt-BR': deepmerge(toolkitLocales['pt-BR'], localePtBr)
  // 'en-US': deepmerge(toolkitLocales['en-US'], localeEnUs),
  // 'de-DE': deepmerge(toolkitLocales['de-DE'], localeDeDe)
}

export default locales
