import { useTranslation } from 'react-i18n-lite'

import Language from '../components/Language'
import { useLoginContext } from './LoginContext'
import { Button, Unifree } from '@yes.technology/react-toolkit'
import { useSessionStorage } from 'shared/hooks'

type Props = {
  goToAuthentication: () => void
}

function Identification({ goToAuthentication }: Props) {
  const { t } = useTranslation()
  const { userID, setUserID, captcha } = useLoginContext()

  const [redirectOrigin] = useSessionStorage(
    'redirectOrigin',
    window.fallbackOrigin
  )

  const handleSignUp = () => {
    const { userRegistrationSitemodelPath } = window
    const url = new URL(redirectOrigin)
    const [subdomain] = url.hostname.split('.')
    const path =
      userRegistrationSitemodelPath[subdomain] ||
      userRegistrationSitemodelPath.default

    window.location.assign(`${url.origin}/${path}`)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    goToAuthentication()
  }

  return (
    <form
      style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
      onSubmit={handleSubmit}
    >
      <Language />
      <Unifree
        label={t('login.identification.user')}
        placeholder={t('login.identification.user')}
        name='email'
        autoFocus
        value={userID}
        onChange={setUserID}
      />

      <Button type='submit' disabled={!captcha || !userID}>
        {t('login.identification.continue')}
      </Button>

      <Button onClick={handleSignUp} variant='secondary'>
        {t('login.identification.want-to-sign-up')}
      </Button>
    </form>
  )
}

export default Identification
