import { postLogin } from 'authentication/shared/api'
import { LoginProps } from 'authentication/shared/types'
import useAuthentication from './useAuthentication'

const useLogin = () => {
  const { isLoading, startLoading, stopLoading, setAuthenticatedData } =
    useAuthentication()

  const login = (props: LoginProps) => {
    const { payload, onSuccess, onError } = props
    startLoading()
    postLogin(payload)
      .then((response) => {
        stopLoading()
        setAuthenticatedData({
          username: payload.email,
          expirationDate: response.data.data?.expiration_date,
          token: response.data.data?.token,
          available_credentials: response.data.data?.available_credentials,
          last_password_change: response.data.data?.last_password_change,
          remember_about_password_change:
            response.data.data.remember_about_password_change
        })
        onSuccess?.(response?.data)
      })
      .catch((error) => {
        console.log(error)
        stopLoading()
        onError?.(error.response.data.message)
      })
  }

  return {
    login,
    isLoadingLogin: isLoading
  }
}

export default useLogin
