import { createContext, useContext } from 'react'

import { AuthenticatedData } from '../types'

type AuthenticationContextType = {
  isLoading: boolean
  startLoading: () => void
  stopLoading: () => void
  authenticatedData: AuthenticatedData
  setAuthenticatedData: (authenticatedData: AuthenticatedData) => void
}

const initialState = {
  isLoading: false,
  startLoading: () => undefined,
  stopLoading: () => undefined,
  authenticatedData: {},
  setAuthenticatedData: () => undefined
}

export const AuthenticationContext =
  createContext<AuthenticationContextType>(initialState)

const useAuthentication = () => {
  const context = useContext(AuthenticationContext)

  if (context === undefined) {
    throw new Error(
      'useAuthentication must be used within a AuthenticationProvider'
    )
  }

  return context
}

export default useAuthentication
