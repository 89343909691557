import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18n-lite'

import * as serviceWorker from './utils/registerServiceWorker'
import Alert from './components/Alert'

const ServiceWorkerWrapper = () => {
  const [showReload, setShowReload] = useState(false)
  const [waitingWorker, setWaitingWorker] = useState<Worker>()

  const onSWUpdate = (registration: { waiting: Worker }) => {
    if (registration.waiting) {
      setShowReload(true)
      setWaitingWorker(registration.waiting)
    }
  }

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate })
  }, [])

  const reloadPage = () => {
    if (waitingWorker) {
      waitingWorker.postMessage({ type: 'SKIP_WAITING' })
      setShowReload(false)
    }
  }

  const { t } = useTranslation()

  return (
    <div>
      {showReload && (
        <Alert
          text={t('system.new-version-available')}
          buttonText={t('system.btn.update')}
          onClick={reloadPage}
        />
      )}
    </div>
  )
}

export default ServiceWorkerWrapper
