import { startRegistration } from '@simplewebauthn/browser'

import {
  generateRegistrationOptions,
  verifyRegistration
} from 'authentication/shared/api'

export async function registration(userId: string) {
  const resp = await generateRegistrationOptions(userId)

  let attestation

  try {
    let { publicKey } = await resp?.data?.data

    publicKey = {
      ...publicKey,
      excludeCredentials: []
    }

    // Pass the options to the authenticator and wait for a response
    attestation = await startRegistration(publicKey)
  } catch (error) {
    let errorMessage = ''

    if (error instanceof Error) {
      /*
       others error handling
       - NotAllowedError
       User clicked cancel, or the registration ceremony timed out

       -> node_modules/@simplewebauthn/browser/dist/bundle/index.js

       */
      if (error.name === 'InvalidStateError') {
        errorMessage =
          'Error: Authenticator was probably already registered by user'
      } else {
        errorMessage = error.message
      }
    }
    console.log('error', errorMessage)
    return {
      success: false,
      message: errorMessage
    }
  }

  const verificationResp = await verifyRegistration(userId, attestation)

  const verificationJSON = await verificationResp?.data

  return {
    success: verificationJSON && verificationJSON.success,
    data: {
      attestation
    }
  }
}
