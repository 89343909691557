import { FormEvent, useState } from 'react'

import { useTranslation } from 'react-i18n-lite'
import { useNavigate } from 'react-router-dom'
import { Button, Unifree } from '@yes.technology/react-toolkit'
import AuthenticationTemplate from 'authentication/shared/components/AuthenticationTemplate'
import ConfirmationPopup from './ConfirmationPopup'

function ChangePassword() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleOnCancel = () => {
    navigate('/credentials')
  }

  const [password, setPassword] = useState('')
  const [isConfirmationPopupOpen, setIsConfirmationPopupOpen] = useState(false)

  const closeConfirmationPopup = () => {
    setIsConfirmationPopupOpen(false)
  }

  const openConfirmationPopup = () => {
    setIsConfirmationPopupOpen(true)
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    openConfirmationPopup()
  }

  return (
    <AuthenticationTemplate title={t('change-password.title')}>
      <h2>{t('change-password.subtitle')}</h2>
      <form
        style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
        onSubmit={handleSubmit}
      >
        <Unifree
          label={t('change-password.label-new-password')}
          placeholder={t('change-password.new-password-placeholder')}
          name='new-password'
          type='password'
          autoFocus
          value={password}
          onChange={setPassword}
          required
        />

        <Button type='submit' disabled={!password}>
          {t('change-password.continue')}
        </Button>

        <Button variant='secondary' onClick={handleOnCancel}>
          {t('change-password.cancel')}
        </Button>
      </form>

      {isConfirmationPopupOpen && (
        <ConfirmationPopup
          password={password}
          closePopup={closeConfirmationPopup}
        />
      )}
    </AuthenticationTemplate>
  )
}

export default ChangePassword
