import jwtDecode from 'jwt-decode'

interface AuthenticationData {
  username: string
  expirationDate: number
  token: string
}

function getAuthDataFromToken(token: string): AuthenticationData | null {
  if (!token) return null

  const decodedHeader = jwtDecode<{ typ: string; alg: string }>(token, {
    header: true
  })

  if (decodedHeader?.typ !== 'JWT' || decodedHeader?.alg !== 'HS256') {
    console.error('Invalid token')
    return null
  }

  const decodedData = jwtDecode<{ email: string; expiration_date: number }>(
    token
  )

  return {
    username: decodedData.email,
    expirationDate: decodedData.expiration_date,
    token
  }
}

export default getAuthDataFromToken
