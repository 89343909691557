import { browserSupportsWebAuthn } from '@simplewebauthn/browser'

import { registration } from 'authentication/Webauthn/registration/'
import { authentication } from 'authentication/Webauthn/authentication/'

const useWebauthn = () => {
  return {
    registration,
    authentication,
    browserSupportsWebAuthn
  }
}

export default useWebauthn
