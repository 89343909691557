import { createContext, useContext } from 'react'

type LoginContextValue = {
  userID: string
  setUserID: (value: string) => void
  password: string
  setPassword: (value: string) => void
  captcha: string
  handleLogin: () => void
}

const LoginContext = createContext<LoginContextValue>({
  userID: '',
  setUserID: () => undefined,
  password: '',
  setPassword: () => undefined,
  captcha: '',
  handleLogin: () => undefined
})

export const useLoginContext = () => useContext(LoginContext)

export default LoginContext
