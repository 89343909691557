import { Outlet, Navigate, useLocation } from 'react-router-dom'

import { HTTPClient } from 'infrastructure/shared/api'
import { useAuthentication } from 'authentication/shared/hooks'

const PrivateRoutes = () => {
  const { authenticatedData } = useAuthentication()
  const location = useLocation()

  HTTPClient.setToken(authenticatedData?.token || '')

  if (!authenticatedData?.token) {
    return <Navigate to='/login' state={{ from: location.pathname }} replace />
  }

  return <Outlet />
}

export default PrivateRoutes
