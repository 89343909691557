import { HierarchyCard } from '@yes.technology/react-toolkit'
import styled from 'styled-components'

export const StyledHierarchyCard = styled(HierarchyCard)`
  width: 448px;
  max-width: calc(100% - 16px);
  margin-top: 10%;
  margin-inline: auto;
`

export const CardContent = styled.div`
  padding-block: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  text-align: center;

  & > h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #303032;
    margin: 0;
  }

  & > h5 {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
  }
`

export const SuccessIconWrapper = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #006bff;
  display: flex;
  align-items: center;
  justify-content: center;
`
