import { useTranslation } from 'react-i18n-lite'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as SuccessIcon } from 'shared/assets/img/icon/sucess.svg'
import YesLogo from 'shared/assets/img/logo-yes-dot.png'
import { Button } from '@yes.technology/react-toolkit'
import { CardContent, StyledHierarchyCard, SuccessIconWrapper } from './styles'

function ChangePasswordSuccess() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleGoBackToLogin = () => {
    navigate('/login', { replace: true })
  }

  return (
    <StyledHierarchyCard>
      <CardContent>
        <img src={YesLogo} alt='Yes' width='82' height='33' />
        <SuccessIconWrapper>
          <SuccessIcon />
        </SuccessIconWrapper>
        <h1>{t('change-password.success.title')}</h1>
        <h5>{t('change-password.success.subtitle')}</h5>
        <Button onClick={handleGoBackToLogin}>
          {t('change-password.success.back-to-login')}
        </Button>
      </CardContent>
    </StyledHierarchyCard>
  )
}

export default ChangePasswordSuccess
