import { useEffect } from 'react'
import { useTranslation } from 'react-i18n-lite'
import { useQuery } from 'shared/utils/url/useQuery'

const useHandleLanguage = () => {
  const { setLanguage } = useTranslation()
  const query = useQuery()
  const lang = query.get('lang')

  useEffect(() => {
    if (lang) {
      setLanguage(lang)
    }
  }, [lang, setLanguage])
}

export default useHandleLanguage
