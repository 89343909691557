import React from 'react'
import {
  AreaLineTitle,
  GreyAreaLine,
  HierarchyCardBody,
  StyledHierarchyCard
} from './styles'

interface HierarchyCardWithAreaLineProps {
  children: React.ReactNode
  title: string
}

const HierarchyCardWithAreaLine: React.FC<HierarchyCardWithAreaLineProps> = ({
  children,
  title
}) => {
  const areaLineTitle = <AreaLineTitle>{title}</AreaLineTitle>

  return (
    <StyledHierarchyCard spacingClass={'doubleXSmall'} level={2}>
      <GreyAreaLine
        heightClass='doubleXSmall'
        centerAreaElement={areaLineTitle}
      />
      <HierarchyCardBody>{children}</HierarchyCardBody>
    </StyledHierarchyCard>
  )
}

export default HierarchyCardWithAreaLine
