import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'

const Webauthn = () => {
  const navigate = useNavigate()
  const [cookies] = useCookies(['_2FA'])

  useEffect(() => {
    if (!cookies._2FA) {
      navigate('/webauthn/registration', { replace: true })
    } else {
      navigate('/webauthn/authentication', { replace: true })
    }
  }, [navigate, cookies._2FA])

  return null
}

export default Webauthn
