import { Routes, Route } from 'react-router-dom'

import AuthenticationRoutes from 'authentication/Routes'

const ApplicationRouter: React.FunctionComponent = (): JSX.Element => {
  return (
    <Routes>
      <Route path='/*' element={<AuthenticationRoutes />} />
    </Routes>
  )
}

export default ApplicationRouter
