import { AreaLine, HierarchyCard } from '@yes.technology/react-toolkit'
import styled from 'styled-components'

export const StyledHierarchyCard = styled(HierarchyCard)`
  & > *:first-child {
    padding: 0;
  }
`

export const GreyAreaLine = styled(AreaLine)`
  background: #dde5ed;
`

export const AreaLineTitle = styled.span`
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
`

export const HierarchyCardBody = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`
