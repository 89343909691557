import { FormEvent, useState } from 'react'
import * as api from 'authentication/shared/api'
import { useTranslation } from 'react-i18n-lite'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  Button,
  Popup,
  Unifree,
  feedbackcard,
  useDisplayUserError
} from '@yes.technology/react-toolkit'

type Props = {
  password: string
  closePopup: () => void
}

const AUTO_CLOSE_TOAST_TIME = 5000

function ConfirmationPopup({ closePopup, password }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const [inputStatusClass, setInputStatusClass] = useState<string>()

  const { displayUserError } = useDisplayUserError()

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (passwordConfirmation !== password) {
      feedbackcard(t('change-password.feedback.error.title'), {
        message: t('change-password.feedback.error.msg'),
        type: 'error',
        autoClose: AUTO_CLOSE_TOAST_TIME
      })
      setInputStatusClass('error')
      return
    }

    try {
      const { data } = await api.patchChangeSelfPassword({
        new_password: passwordConfirmation
      })

      if (!data?.success) {
        displayUserError({})
        return
      }

      const dontAskAgain = searchParams.get('dontAskAgain')

      if (dontAskAgain === 'true') {
        await api.patchDontShowChangePassword()
      }

      navigate('/change-password-success')
    } catch (error) {
      displayUserError({})
    }
  }

  return (
    <Popup isOpen onClose={closePopup}>
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
          width: 416,
          maxWidth: '100%'
        }}
        onSubmit={handleSubmit}
      >
        <Unifree
          label={t('change-password.label-confirm-password')}
          placeholder={t('change-password.confirm-password-placeholder')}
          name='confirm-password'
          type='password'
          autoFocus
          value={passwordConfirmation}
          onChange={setPasswordConfirmation}
          required
          statusClass={inputStatusClass}
        />

        <Button type='submit'>{t('change-password.continue')}</Button>

        <Button variant='secondary' onClick={closePopup}>
          {t('change-password.cancel')}
        </Button>
      </form>
    </Popup>
  )
}

export default ConfirmationPopup
