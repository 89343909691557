import { useState } from 'react'
import jwtDecode from 'jwt-decode'
import { useTranslation } from 'react-i18n-lite'
import { useCookies } from 'react-cookie'

import { useAuthentication } from 'authentication/shared/hooks'
import { useWebauthn } from './hooks'
import AuthenticationTemplate from 'authentication/shared/components/AuthenticationTemplate'
import { useDocumentTitle } from 'infrastructure/shared/hooks'
import { useNavigate } from 'react-router-dom'
import { Button } from '@yes.technology/react-toolkit'

const Registration = () => {
  const { t } = useTranslation()
  const { authenticatedData } = useAuthentication()
  const [, setCookie] = useCookies(['_2FA'])

  const navigate = useNavigate()

  const [state, setState] = useState({
    success: false
  })

  useDocumentTitle({ title: t('webauthn.title') })

  const { browserSupportsWebAuthn, registration } = useWebauthn()

  let userId: string
  if (authenticatedData?.token) {
    const decoded = jwtDecode<{ user_id: string }>(authenticatedData.token)
    userId = decoded.user_id
  }

  const notNow = () => {
    navigate('/credentials')
  }

  if (!browserSupportsWebAuthn()) {
    console.log('This browser does not support WebAuthn')
    notNow()
    return null
  }

  const handleRegistration = async () => {
    registration(userId)
      .then((response) => {
        if (response?.success) {
          setState((prevState) => ({
            ...prevState,
            success: true
          }))
          setCookie('_2FA', userId, {
            path: '/',
            secure: true,
            sameSite: true,
            expires: new Date(
              new Date().setFullYear(new Date().getFullYear() + 1)
            )
          })
          notNow()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <AuthenticationTemplate>
      <h2>{t('webauthn.title')}</h2>
      <p>{t('webauthn.registration.des')}</p>
      <Button
        style={{ marginBottom: 8 }}
        disabled={state.success}
        onClick={handleRegistration}
      >
        {t('webauthn.registration.btn.secure-account')}
      </Button>
      <Button variant='secondary' disabled={state.success} onClick={notNow}>
        {t('system.btn.not-now')}
      </Button>
    </AuthenticationTemplate>
  )
}

export default Registration
