import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18n-lite'

import { useRecaptcha, useLogin } from 'authentication/shared/hooks'
import AuthenticationTemplate from 'authentication/shared/components/AuthenticationTemplate'
import Identification from './Identification'
import Authentication from './Authentication'
import LoginContext from './LoginContext'
import {
  feedbackcard,
  useDisplayUserError
} from '@yes.technology/react-toolkit'

const LoginContainer = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const isWebauthn = searchParams.get('webauthn') && true
  const { login } = useLogin()
  const { captcha } = useRecaptcha()
  const navigate = useNavigate()
  const [inputMode, setInputMode] = useState('identification')

  const goToIdentification = () => setInputMode('identification')
  const goToAuthentication = () => setInputMode('authentication')

  const [userID, setUserID] = useState('')
  const [password, setPassword] = useState('')

  const navigateNextStep = () => {
    navigate(isWebauthn ? '/webauthn' : '/credentials')
  }

  const { displayUserError } = useDisplayUserError()

  const handleLogin = () => {
    const loginProps = {
      payload: {
        email: userID,
        password,
        captcha
      },
      onSuccess: () => {
        feedbackcard(t('login.feedback.success.title'), {
          message: t('login.feedback.success.msg'),
          type: 'success'
        })
        navigateNextStep()
      },
      onError: (message: string) => {
        displayUserError({
          title: t('login.feedback.error.title'),
          userMessageFallback: t('login.feedback.error.msg'),
          error: { message }
        })
      }
    }

    login(loginProps)
  }

  const isIdentification = inputMode === 'identification'
  const isAuthentication = inputMode === 'authentication'
  const title = t(
    `login.${isIdentification ? 'identification' : 'authentication'}.title`
  )

  return (
    <LoginContext.Provider
      value={{ userID, setUserID, password, setPassword, captcha, handleLogin }}
    >
      <AuthenticationTemplate>
        <h2>{title}</h2>
        {isIdentification && (
          <Identification goToAuthentication={goToAuthentication} />
        )}
        {isAuthentication && (
          <Authentication goToIdentification={goToIdentification} />
        )}
      </AuthenticationTemplate>
    </LoginContext.Provider>
  )
}

export default LoginContainer
