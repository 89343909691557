import {
  AuthenticationCredentialJSON,
  RegistrationCredentialJSON
} from '@simplewebauthn/typescript-types'
import { AxiosError, AxiosResponse } from 'axios'

import { HTTPClient } from 'infrastructure/shared/api'
import {
  CredentialResponse,
  LoginRequestPayload,
  UpgradeTokenRequestPayload,
  TokenResponse,
  LoginResponse
} from 'authentication/shared/types'

const { msLogin } = window.apiUrls || { msLogin: '' }
let { msUpgradeToken } = window.apiUrls || { msUpgradeToken: '' }

export function postLogin(payload: LoginRequestPayload) {
  return HTTPClient.post<LoginResponse>(`${msLogin}login`, payload)
}

type ReloadTokenData = { token: string }

export type ReloadTokenResponse = {
  data: {
    expiration_date: number
    token: string
  }
  httpStatusCode: number
  success: boolean
}

export function postReloadToken(
  data: ReloadTokenData
): Promise<AxiosResponse<ReloadTokenResponse>> {
  return HTTPClient.post(`${window.apiUrls.msAuth}reload-token`, data)
}

type ChangePasswordData = { new_password: string }
type ChangePasswordResponse = {
  success: boolean
  message: string
}

export function patchChangeSelfPassword(data: ChangePasswordData) {
  return HTTPClient.patch<ChangePasswordResponse>(
    `${window.apiUrls.msLogin}change-self-password`,
    data
  ).catch((error: AxiosError) => {
    throw error.response?.data
  })
}

export const getCredentials = (): Promise<
  AxiosResponse<{ data: CredentialResponse[] }>
> => {
  return HTTPClient.get(window.credentials)
}

export function generateRegistrationOptions(
  userId: string
): Promise<AxiosResponse> {
  return HTTPClient.get(
    `${window.apiUrls.msAuth}webauthn/generate-registration-options?user_id=${userId}`
  )
}

export function verifyRegistration(
  userId: string,
  body: RegistrationCredentialJSON | undefined
): Promise<AxiosResponse> {
  return HTTPClient.post(
    `${window.apiUrls.msAuth}webauthn/verify-registration?user_id=${userId}`,
    body
  )
}

export function generateAuthenticationOptions(
  userId: string
): Promise<AxiosResponse> {
  return HTTPClient.get(
    `${window.apiUrls.msAuth}webauthn/generate-authentication-options?user_id=${userId}`
  )
}

export function verifyAuthentication(
  userId: string,
  body: AuthenticationCredentialJSON | undefined
): Promise<AxiosResponse> {
  return HTTPClient.post(
    `${window.apiUrls.msAuth}webauthn/verify-authentication?user_id=${userId}`,
    body
  )
}

export function setMsUpgradeTokenUrl(newUrl: string) {
  msUpgradeToken = newUrl
}

export function postUpgradeToken(
  payload: UpgradeTokenRequestPayload
): Promise<AxiosResponse<TokenResponse>> {
  return HTTPClient.post(
    `${msUpgradeToken}upgrade-token-with-credentials`,
    payload
  )
}

export function patchDontShowChangePassword() {
  return HTTPClient.patch(
    `${window.apiUrls.msLogin}change-self-password-options`,
    { remember_about_password_change: '0' }
  )
}
