import { YesLoading } from '@yes.technology/react-toolkit'
import { ActiveSessionContainer } from '../styles'

export default function ActiveSessionLoading() {
  return (
    <ActiveSessionContainer
      style={{ alignItems: 'center', justifyContent: 'center' }}
    >
      <YesLoading />
    </ActiveSessionContainer>
  )
}
