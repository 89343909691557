import { useLocalStorage } from 'shared/hooks'

import * as api from 'authentication/shared/api'

import {
  UpgradeTokenRequestPayload,
  AvailableCredentials
} from 'authentication/shared/types'
import { HTTPClient } from 'infrastructure/shared/api'
import { Must } from 'shared/types/utility.type'

const useUpgradeToken = () => {
  const [authenticatedData, setAuthenticatedData] = useLocalStorage('auth')

  const upgradeToken = async (credentials?: AvailableCredentials) => {
    if (!credentials) return

    const payload = validateAndParseToUpgradeTokenPayload(
      authenticatedData instanceof Object &&
        'token' in authenticatedData &&
        typeof authenticatedData.token === 'string'
        ? authenticatedData.token
        : '',
      credentials
    )

    if (payload) {
      const response = await api.postUpgradeToken(payload)
      const upgradedToken = response?.data?.data.token

      if (upgradedToken) {
        HTTPClient.setToken(upgradedToken)
        setAuthenticatedData({
          ...(authenticatedData instanceof Object ? authenticatedData : {}),
          token: upgradedToken
        })
        return upgradedToken
      }
    }
    return
  }

  return upgradeToken
}

export default useUpgradeToken

function validateAndParseToUpgradeTokenPayload(
  token: string,
  available_credentials: AvailableCredentials
): UpgradeTokenRequestPayload | undefined {
  if (!validateUpgradeTokenPayload(token, available_credentials)) {
    return undefined
  }

  return {
    credentials: {
      applicationsystem_uuid:
        available_credentials.applicationsystems_info[0].uuid,
      organization_uuid: available_credentials.organizations_info[0].uuid,
      user_uuid: available_credentials.user_info[0].uuid
    },
    token: token
  }
}

export function validateUpgradeTokenPayload(
  token?: string,
  available_credentials?: AvailableCredentials
): available_credentials is Must<AvailableCredentials> {
  return (
    (token?.length || 0) > 0 &&
    (available_credentials?.user_info?.length || 0) > 0 &&
    (available_credentials?.organizations_info?.length || 0) > 0 &&
    (available_credentials?.applicationsystems_info?.length || 0) > 0
  )
}
