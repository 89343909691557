import { useEffect } from 'react'
import { useTranslation } from 'react-i18n-lite'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'

import AuthenticationTemplate from 'authentication/shared/components/AuthenticationTemplate'
import { Button, feedbackcard } from '@yes.technology/react-toolkit'

const Delete = () => {
  const { t } = useTranslation()

  const [cookies, , removeCookie] = useCookies(['_2FA'])
  const navigate = useNavigate()

  useEffect(() => {
    if (!cookies._2FA) {
      navigate('/login', { replace: true })
    }
  }, [navigate, cookies._2FA])

  const handleDelete = () => {
    removeCookie('_2FA', { path: '/' })
    feedbackcard(t('webauthn.delete.feedback.success.title'), {
      message: t('webauthn.delete.feedback.success.msg'),
      type: 'success'
    })
    navigate('/login', { replace: true })
  }

  const notNow = () => {
    console.log('not now')
  }

  return (
    <AuthenticationTemplate>
      <h2>{t('webauthn.title')}</h2>
      <p>{t('webauthn.delete.des')}</p>
      <Button className='button button-workflow' onClick={handleDelete}>
        {t('webauthn.delete.btn.delete')}
      </Button>
      <Button
        className='button button-secondary-regular mt-20'
        onClick={notNow}
      >
        {t('system.btn.not-now')}
      </Button>
    </AuthenticationTemplate>
  )
}

export default Delete
