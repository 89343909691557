import { useState } from 'react'
import { AuthenticatedData } from '../types'

const usePasswordChangeSuggestion = () => {
  const [shouldOpenPasswordChange, setShouldOpenPasswordChange] =
    useState(false)

  const handleSuggestPasswordChange = ({
    last_password_change,
    remember_about_password_change
  }: Pick<
    AuthenticatedData,
    'last_password_change' | 'remember_about_password_change'
  >) => {
    const oneYearAgo = new Date(
      new Date().setFullYear(new Date().getFullYear() - 1)
    )

    const passwordChangedLastYear =
      last_password_change && new Date(last_password_change) > oneYearAgo

    const userWantsToIgnoreChange = remember_about_password_change === '0'

    const shouldSuggestPasswordChange =
      !passwordChangedLastYear && !userWantsToIgnoreChange

    if (shouldSuggestPasswordChange) {
      setShouldOpenPasswordChange(true)
      return true
    }

    setShouldOpenPasswordChange(false)
    return false
  }

  return {
    handleSuggestPasswordChange,
    shouldOpenPasswordChange
  }
}

export default usePasswordChangeSuggestion
