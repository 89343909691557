import { useTranslation } from 'react-i18n-lite'
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'

import { useAuthentication } from 'authentication/shared/hooks'
import { useWebauthn } from './hooks'
import AuthenticationTemplate from 'authentication/shared/components/AuthenticationTemplate'
import {
  Button,
  feedbackcard,
  useDisplayUserError
} from '@yes.technology/react-toolkit'

const Authentication = () => {
  const { t } = useTranslation()
  const { setAuthenticatedData } = useAuthentication()

  const [cookies] = useCookies(['_2FA'])
  const navigate = useNavigate()

  useEffect(() => {
    if (!cookies._2FA) {
      navigate('/login', { replace: true })
    }
  }, [navigate, cookies._2FA])

  const [state, setState] = useState({
    success: false
  })

  const { browserSupportsWebAuthn, authentication } = useWebauthn()

  const { displayUserError } = useDisplayUserError()

  const handleAuthentication = () => {
    authentication(cookies._2FA)
      .then((response) => {
        if (response?.success) {
          setState((prevState) => ({
            ...prevState,
            success: true
          }))
          setAuthenticatedData({
            expirationDate: response?.data?.expiration_date,
            token: response.data?.token
          })
          navigate('/credentials')
        } else {
          feedbackcard(t('general-feedback.error.title'), {
            message: t('webauthn.feedback.error.msg'),
            type: 'error',
            autoClose: 2000
          })
        }
      })
      .catch(() => {
        displayUserError({})
      })
  }

  if (!browserSupportsWebAuthn()) {
    console.log('This browser does not support WebAuthn')
    feedbackcard(t('webauthn.feedback.error.title'), {
      message: t('webauthn.feedback.error.msg'),
      type: 'error',
      autoClose: 2000
    })
    return null
  }

  return (
    <AuthenticationTemplate>
      <h2>{t('webauthn.title')}</h2>
      <p>{t('webauthn.authentication.des')}</p>
      <Button disabled={state.success} onClick={handleAuthentication}>
        {t('webauthn.authentication.btn.authenticate')}
      </Button>
    </AuthenticationTemplate>
  )
}

export default Authentication
