import { CredentialResponse, CredentialOptions } from '../types'
import { AvailableCredentials } from '../types'
import { CredentialItem } from '../types/authentication.type'

const defaultAvailableCredentials: AvailableCredentials = {
  user_info: [],
  organizations_info: [],
  applicationsystems_info: [],
  applicationsystem_uuids: [],
  organization_uuids: [],
  user_uuids: []
}

const parseToResponseList = (
  available_credentials: AvailableCredentials = defaultAvailableCredentials
): CredentialResponse[] => {
  const envResponse: CredentialResponse = {
    uuid: '4c1682a0-9b1c-4774-a933-1e0f2c670c6a',
    des: 'Ambiente do Ecossistema',
    options: new Array({
      id: 'Yes Ecosystem',
      value: 'Yes Ecosystem',
      label: 'Yes Ecosystem'
    })
  }
  const appResponse: CredentialResponse = {
    uuid: 'f355b6c5-80a9-447a-8325-0500842aac69',
    des: 'Sistema de Aplicações',
    options: formatOptions(available_credentials.applicationsystems_info, false)
  }
  const orgResponse: CredentialResponse = {
    uuid: '5d34636d-63fa-4972-844e-2812f01193bc',
    des: 'Credencial da Organização',
    options: formatOptions(available_credentials.organizations_info, true)
  }
  const userResponse: CredentialResponse = {
    uuid: '5508f16b-ac3c-41ce-9186-a1cb07e94698',
    des: 'Credencial do Usuário',
    options: formatOptions(available_credentials.user_info, true)
  }
  return [envResponse, appResponse, orgResponse, userResponse]
}

function formatOptions(
  optionsUuids: CredentialItem[] | null | undefined,
  withSID: boolean
): CredentialOptions[] {
  if (!optionsUuids) {
    return []
  }

  return optionsUuids.map(({ uuid, sid, des }) => ({
    id: uuid,
    value: uuid,
    label: withSID ? `${sid} |  ${des}` : des
  }))
}

export default parseToResponseList
