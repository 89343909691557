import { LoadingPage } from '@yes.technology/react-toolkit'
import styled from 'styled-components'

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 1);

  @media (max-width: 768px) {
    text-align: center;
  }
`

type LoadingProps = {
  active: boolean
}

const Loading = ({ active }: LoadingProps) => {
  if (!active) {
    return null
  }

  return (
    <LoadingOverlay>
      <LoadingPage />
    </LoadingOverlay>
  )
}

export default Loading
